import { useEffect, useState } from 'react'

import './LangSelector.css'

import LangSelectorItem from '../LangSelectorItem'

import arrow_img from '../../assets/imgs/arrow_single.png'
import br_img from '../../assets/imgs/br-2.png'
import us_img from '../../assets/imgs/us-2.png'
import fr_img from '../../assets/imgs/fr-2.png'
import { useTranslation } from 'react-i18next'

const flags = {
    'pt' : br_img,
    'en' : us_img,
    'fr' : fr_img
}

export default function LangSelector(props) {
    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const notSelectedLangs = Object.keys(flags).filter(l => l!==props.current)

    const setCurrentLanguage = lang => i18n.changeLanguage(lang)

    useEffect(() => {
        const handleClick = e => {
            const listLangSelectors = Array.from(document.querySelectorAll('.lang-selector'))
            const clickOutside = !listLangSelectors.reduce((inside, next) => inside || next.contains(e.target), false)

            if(clickOutside) {
                setOpen(false)
            }
        }

        window.addEventListener('click', handleClick)
        return () => window.removeEventListener('click', handleClick)
    }, [])

    return (
        <div className="lang-selector">
            <div className={`lang-selector--dropdown ${open?'lang-selector--open':''}`}>
                {notSelectedLangs.map((l, i) => {
                    return (<div className="lang-selector--option" key={i}>
                        <LangSelectorItem
                            img={flags[l]}
                            lang={l}
                            customClickEvent={()=>{
                                setCurrentLanguage(l)
                                setOpen(false)
                            }}
                        />
                    </div>)
                })}
            </div>
            <div className="lang-selector--top" onClick={e => {
                    setOpen(!open)
                }}>
                <div className="lang-selector--selected">
                    <LangSelectorItem
                        img={flags[props.current]}
                        lang={props.current}
                    />
                </div>
                <div className="lang-selector--arrow">
                    <img className={open?'lang-selector--invert':''} src={arrow_img} alt="Drop Down" />
                </div>
            </div>
        </div>
    )
}