import menu from './namespaces/menu.json'
import home from './namespaces/home.json'
import about from './namespaces/about.json'
import education from './namespaces/education.json'
import skills from './namespaces/skills.json'
import experience from './namespaces/experience.json'
import contact from './namespaces/contact.json'

const translation = {
    fr: {
        menu: menu,
        home: home,
        about: about,
        education: education,
        skills: skills,
        experience: experience,
        contact: contact
    }
}

export {translation}