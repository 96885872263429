import {translation as ptTranslation} from './pt'
import {translation as enTranslation} from './en'
import {translation as frTranslation} from './fr'

const translations = {
    ...ptTranslation,
    ...enTranslation,
    ...frTranslation
}

export {translations}