import { useLayoutEffect, useRef, useState } from 'react'
import './ExperienceItem.css'

import { createPopup } from './popupExperience'

import close_img from '../../assets/imgs/close.png'

import { useTranslation } from 'react-i18next'

export default function ExperienceItem(props) {
    const [width, setWidth] = useState(0)
    const ref = useRef(null)

    const { t } = useTranslation('experience')

    useLayoutEffect(() => {
        const handleProportion = () => {
            setWidth(ref.current.clientHeight*(250/342))
        }
        handleProportion()

        window.addEventListener('resize', handleProportion)
        return () => window.removeEventListener('resize', handleProportion)
    })

    return (
        <div ref={ref} className="experience-item" style={{width : `${width}px`}}>
            <div onClick={() => createPopup({
                close_img:close_img,
                name:props.name,
                img:props.img,
                period:props.period,
                text:props.text,
                info_url:props.info_url,
                info:t('slider.info')
            })} className="experience-item--content">
                <p className="text experience-item--title">{props.name}</p>
                <p className="text experience-item--period">{props.period}</p>
                <p className="text experience-item--text">{props.text}</p>
            </div>
            <div className="experience-item--image">
                <img src={props.img} alt={props.name} />
            </div>
        </div>
    )
}