import './SkillItem.css'

import close_img from '../../assets/imgs/close.png'

import { createPopup } from './popupSkill'

export default function SkillItem(props) {
    return (
        <div onClick={()=>createPopup({...props, close_img})} className="skill-item">
            <img src={props.img} alt={props.skill} />
        </div>
    )
}