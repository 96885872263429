import './Computer.css'
import pc_img from '../../assets/imgs/pc_obj.png'
import base_img from '../../assets/imgs/pc_base.png'

export default function Computer() {
    return(
        <div className="computer">
            <img src={base_img} alt="computer base" />
            <img className='pc' src={pc_img} alt="computer" />
        </div>
    )
}