import './Experience.css'
import ExperienceSlider from '../ExperienceSlider'
import Arrow from '../Arrow'

import certia_img from '../../assets/imgs/certia.png'
import minibee_img from '../../assets/imgs/minibee.png'
import robot_img from '../../assets/imgs/robot.png'

import { useTranslation } from 'react-i18next'

export default function Experience(props) {
    const { t } = useTranslation('experience')

    return (
        <div className="experience">
            <div className="experience--wrapper">
                <div className="experience--title">
                    <p className="title">{t('title')}</p>
                </div>
                <ExperienceSlider items={[
                    {
                        name:t('slider.certia.name'),
                        img:certia_img,
                        period:t('slider.certia.period'),
                        text:t('slider.certia.text'),
                        info_url:'http://certiaengineering.com/'
                    },
                    {
                        name:t('slider.minibee.name'),
                        img:minibee_img,
                        period:t('slider.minibee.period'),
                        text:t('slider.minibee.text'),
                        info_url:'https://www.technoplane.com/fr/mini-bee-fr/'
                    },
                    {
                        name:t('slider.annl.name'),
                        img:robot_img,
                        period:t('slider.annl.period'),
                        text:t('slider.annl.text'),
                        info_url:'https://github.com/danilorodrigues128/Artificial-Neural-Network-Library'
                    }
                ]} />
            </div>
            <Arrow nextSectionCallback={props.nextSectionCallback} />
        </div>
    )
}