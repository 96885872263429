import { useState, useEffect } from 'react'

import './Menu.css'
import LangSelector from '../LangSelector'

import menu_img from '../../assets/imgs/menu.png'
import { useTranslation } from 'react-i18next'

export default function Menu(props) {
    const { t, i18n } = useTranslation('menu')

    const [openMenu, setOpenMenu] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState(i18n.resolvedLanguage)

    const toggleMenu = () => {
        setOpenMenu(!openMenu)
    }

    useEffect(() => {
        setCurrentLanguage(i18n.resolvedLanguage)
    }, [i18n.resolvedLanguage])

    useEffect(() => {
        const handleClick = e => {
            const clickOutside = !document.querySelector('.menu--responsive').contains(e.target)

            if(clickOutside) {
                setOpenMenu(false)
            }
        }

        window.addEventListener('click', handleClick)
        return () => window.removeEventListener('click', handleClick)
    }, [])

    return (
        <>
            <nav className={`menu ${props.show?'':'menu--hide'}`}>
                <div className="menu--content">
                    <p onClick={()=>props.navCallback(1)} className="text">{t('home')}</p>
                    <p onClick={()=>props.navCallback(2)} className="text">{t('about')}</p>
                    <p onClick={()=>props.navCallback(3)} className="text">{t('education')}</p>
                    <p onClick={()=>props.navCallback(4)} className="text">{t('skills')}</p>
                    <p onClick={()=>props.navCallback(5)} className="text">{t('experience')}</p>
                    <p onClick={()=>props.navCallback(6)} className="text">{t('contact')}</p>
                </div>
                <div className="menu--lang">
                    <LangSelector current={currentLanguage} />
                </div>
            </nav>
            <nav className={`menu--responsive ${props.show?'':'menu--hide'}`}>
                <div onClick={toggleMenu} className={`menu--responsive--image ${openMenu?'menu--open':''}`}>
                    <img src={menu_img} alt="Menu Icon" />
                </div>
                <div className={`menu--responsive--body ${openMenu?'menu--open':''}`}>
                    <div className="menu--responsive--lang">
                        <LangSelector current={currentLanguage} />
                    </div>
                    <div className="menu--responsive--content">
                        <p onClick={()=>{props.navCallback(1);setOpenMenu(false)}} className="text">{t('home')}</p>
                        <p onClick={()=>{props.navCallback(2);setOpenMenu(false)}} className="text">{t('about')}</p>
                        <p onClick={()=>{props.navCallback(3);setOpenMenu(false)}} className="text">{t('education')}</p>
                        <p onClick={()=>{props.navCallback(4);setOpenMenu(false)}} className="text">{t('skills')}</p>
                        <p onClick={()=>{props.navCallback(5);setOpenMenu(false)}} className="text">{t('experience')}</p>
                        <p onClick={()=>{props.navCallback(6);setOpenMenu(false)}} className="text">{t('contact')}</p>
                    </div>
                </div>
            </nav>
        </>
    )
}