import './LangItem.css'

export default function LangItem(props) {
    return(
        <div className="langitem">
            <img src={props.flag} alt={props.lang} />
            <p className="text">{props.lang}</p>
            <div className={`langitem--level ${props.level>=1?'langitem--level--filled':''}`}></div>
            <div className={`langitem--level ${props.level>=2?'langitem--level--filled':''}`}></div>
            <div className={`langitem--level ${props.level>=3?'langitem--level--filled':''}`}></div>
            <div className={`langitem--level ${props.level>=4?'langitem--level--filled':''}`}></div>
            <div className={`langitem--level ${props.level>=5?'langitem--level--filled':''}`}></div>
        </div>
    )
}