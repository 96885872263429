import ContactButton from '../ContactButton'
import Footer from '../Footer'
import './Contact.css'

import { useTranslation } from 'react-i18next'

import linkedin_img from '../../assets/imgs/linkedin.png'
import email_img from '../../assets/imgs/email.png'
import email_hover_img from '../../assets/imgs/email_hover.png'
import github_img from '../../assets/imgs/github.png'

export default function Contact() {
    const { t } = useTranslation('contact')

    return (
        <div className="contact">
            <div className="contact--wrapper">
                <div className="contact--title">
                    <h1 className="title">{t('title')}</h1>
                </div>
                <div className="contact--content">
                    <div className="contact--linkedin">
                        <ContactButton
                            img={linkedin_img}
                            text='Danilo Rodrigues'
                            color='#0077B5'
                            url='https://www.linkedin.com/in/danilorodrigues128'
                        />
                    </div>
                    <div className="contact--email">
                        <ContactButton
                            img={email_img}
                            img_hover={email_hover_img}
                            text='danilorodrigues128'
                            sub='@usp.br'
                            color='#FF5023'
                            url='mailto:danilorodrigues128@usp.br'
                        />
                    </div>
                    <div className="contact--github">
                        <ContactButton
                            img={github_img}
                            text='danilorodrigues128'
                            color='#111111'
                            url='https://github.com/danilorodrigues128'
                        />
                    </div>
                </div>
                <div className="contact--centralizer"></div>
            </div>
            <Footer />
        </div>
    )
}