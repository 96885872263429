import './Skills.css'
import SkillsSlider from '../SkillsSlider'
import LangItem from '../LangItem'
import Arrow from '../Arrow'

import br_img from '../../assets/imgs/br-2.png'
import us_img from '../../assets/imgs/us-2.png'
import fr_img from '../../assets/imgs/fr-2.png'

import { useTranslation } from 'react-i18next'

export default function Skills(props) {
    const { t } = useTranslation('skills')

    return (
        <div className="skills">
            <div className="skills--wrapper">
                <div className="skills--title">
                    <p className="title">{t('title')}</p>
                </div>
                <SkillsSlider />
                <div className="skills--lang">
                    <LangItem
                        lang={t('languages.pt')}
                        flag={br_img}
                        level='5'
                    />
                    <LangItem
                        lang={t('languages.en')}
                        flag={us_img}
                        level='4'
                    />
                    <LangItem
                        lang={t('languages.fr')}
                        flag={fr_img}
                        level='4'
                    />
                </div>
            </div>
            <Arrow nextSectionCallback={props.nextSectionCallback} />
        </div>
    )
}