import './App.css'

import Menu from './components/Menu';
import Loading from './components/Loading';
import Home from "./components/Home";
import About from './components/About';
import Education from './components/Education';
import Skills from './components/Skills';
import Experience from './components/Experience';
import Contact from './components/Contact';

import ReactFullpage from '@fullpage/react-fullpage';
import { useState } from 'react';

function App() {
  const [showMenu, setShowMenu] = useState(false)
  const [needToMove, setNeedToMove] = useState(0)
  const [loading, setLoading] = useState(true)

  function handleMenu (origin, destination) {
    if(origin.index === 0) {
      setShowMenu(true)
    }
    
    if(destination.index === 0) {
      setShowMenu(false)
    }
  }

  function handleLoading() {
    setTimeout(()=>setLoading(false),2000)
  }
  
  function moveTo(section) {
    setNeedToMove(section)
  }
  
  return (
    <div className='app'>
        <Menu show={showMenu} navCallback={moveTo} id='menu-component' />
        <Loading show={loading} />
        <ReactFullpage
          licenseKey='KLEG7-IBMG6-WMGIK-A6P68-OTLJJ'
          onLeave={handleMenu}
          afterRender={handleLoading}

          render={({ state, fullpageApi }) => {
            if(fullpageApi && needToMove !== 0) {
              fullpageApi.moveTo(needToMove)
              setNeedToMove(0)
            }

            const nextSection = fullpageApi ? fullpageApi.moveSectionDown : null

            return (
              <ReactFullpage.Wrapper>
                <div className='section'>
                  <Home nextSectionCallback={nextSection} />
                </div>
                <div className='section'>
                  <About nextSectionCallback={nextSection} />
                </div>
                <div className='section'>
                  <Education nextSectionCallback={nextSection} />
                </div>
                <div className='section'>
                  <Skills nextSectionCallback={nextSection} />
                </div>
                <div className='section'>
                  <Experience nextSectionCallback={nextSection} />
                </div>
                <div className='section'>
                  <Contact />
                </div>
              </ReactFullpage.Wrapper>
            )
          }}
        />
    </div>
  );
}

export default App;
