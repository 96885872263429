function closePopup(element, background, popup, title, text, links) {
    background.classList.remove('experience-item--popup--open')
    popup.classList.remove('experience-item--popup--open')
    title.classList.remove('experience-item--popup--open')
    text.classList.remove('experience-item--popup--open')
    links.classList.remove('experience-item--popup--open')

    setTimeout(()=>element.remove(), 500)
}

function createPopup(props) {
    const content = `
        <div class='experience-item--popup--background'></div>
        <div class='experience-item--popup'>
            <div class="experience-item--popup--close">
                <img src='${props.close_img}' alt="Close" />
            </div>
            <div class="experience-item--popup--content">
                <div class="experience-item--popup--body">
                    <div class="experience-item--popup--title">
                        <h1 class="text">${props.name}</h1>
                        <p class="text">${props.period}</p>
                    </div>
                    <div class="experience-item--popup--text">
                        <p class="text">${props.text}</p>
                    </div>
                </div>
                <div class="experience-item--popup--links">
                    <div class="experience-item--popup--moreinfo">
                        <a target='_blank' rel='noopener noreferrer' href=${props.info_url} class="text">${props.info}</a>
                    </div>
                </div>
            </div>
        </div>
    `

    const element = document.createElement('div')
    element.innerHTML = content
    element.classList.add('experience-item--popup--group')

    document.querySelector('.app').insertAdjacentElement('afterbegin', element)

    const background = document.querySelector('.experience-item--popup--background')
    const popup = document.querySelector('.experience-item--popup')
    const closeButton = document.querySelector('.experience-item--popup--close')
    const title = document.querySelector('.experience-item--popup--title')
    const text = document.querySelector('.experience-item--popup--text')
    const links = document.querySelector('.experience-item--popup--links')

    background.onclick = () => closePopup(element, background, popup, title, text, links)
    closeButton.onclick = () => closePopup(element, background, popup, title, text, links)

    setTimeout(() => {
        background.classList.add('experience-item--popup--open')
        popup.classList.add('experience-item--popup--open')
        title.classList.add('experience-item--popup--open')
        text.classList.add('experience-item--popup--open')
        links.classList.add('experience-item--popup--open')
    }, 10)
}

module.exports = {
    createPopup
}