import { useEffect } from 'react'

import './ExperienceSlider.css'
import ExperienceItem from '../ExperienceItem'

import arrow_img from '../../assets/imgs/arrow_single.png'

export default function ExperienceSlider(props) {
    const slideLeft = () => {
        const items = document.querySelector('.experience-slider--items')
        const left = document.querySelector('.experience-slider--left')
        const right = document.querySelector('.experience-slider--right')
        
        let newPosition = items.offsetLeft + (items.clientHeight*(250/342) + 10)
        if(newPosition > items.clientWidth/2) {
            newPosition = items.clientWidth/2
            left.classList.remove('experience-slider--visible')
        }

        right.classList.add('experience-slider--visible')
        items.style.left = `${newPosition}px`
    }

    const slideRight = () => {
        const slider = document.querySelector('.experience-slider')
        const items = document.querySelector('.experience-slider--items')
        const left = document.querySelector('.experience-slider--left')
        const right = document.querySelector('.experience-slider--right')
        
        let newPosition = items.offsetLeft - (items.clientHeight*(250/342) + 10)
        if(newPosition < slider.clientWidth - items.clientWidth/2) {
            newPosition = slider.clientWidth - items.clientWidth/2
            right.classList.remove('experience-slider--visible')
        }
        left.classList.add('experience-slider--visible')

        items.style.left = `${newPosition}px`
    }

    useEffect(() => {
        const handleOverflow = () => {
            const left = document.querySelector('.experience-slider--left')
            const right = document.querySelector('.experience-slider--right')
            
            const slider = document.querySelector('.experience-slider')
            const items = document.querySelector('.experience-slider--items')

            if(items.clientWidth > slider.clientWidth) {
                if(items.offsetLeft < items.clientWidth/2)
                    left.classList.add('experience-slider--visible')
                else
                    left.classList.remove('experience-slider--visible')

                if(items.offsetLeft > slider.clientWidth - items.clientWidth/2)
                    right.classList.add('experience-slider--visible')
                else
                    right.classList.remove('experience-slider--visible')
            } else {
                left.classList.remove('experience-slider--visible')
                right.classList.remove('experience-slider--visible')
            }
        }
        setTimeout(()=>handleOverflow(),1000)
        
        window.addEventListener('resize', handleOverflow)
        return () => window.removeEventListener('resize', handleOverflow)
    }, [])

    return (
        <div className="experience-slider">
            <div onClick={slideLeft} className="experience-slider--left">
                <img src={arrow_img} alt="Slide Left" />
            </div>
            <div onClick={slideRight} className="experience-slider--right">
                <img src={arrow_img} alt="Slide Right" />
            </div>
            <div className="experience-slider--items">
                {props.items.map((item, index) => {
                    return (<ExperienceItem
                        key={index}
                        name={item.name}
                        img={item.img}
                        period={item.period}
                        text={item.text}
                        info_url={item.info_url}
                    />)
                })}
            </div>
        </div>
    )
}