import './Degree.css'

export default function Degree(props) {
    return (
        <>
            <div className="degree" style={{maxWidth : '702px'}}>
                <a href={props.url} target='_blank' rel='noopener noreferrer' className="text">{props.degree} <span>| {props.university}</span></a>
                <div className="degree--info">
                    <p className='text'>{props.period}</p>
                    <p className='text'>{props.city} <img src={props.flag} alt="Flag" /></p>
                </div>
            </div>
            <div className="degree--responsive" style={{maxWidth : '702px'}}>
                <a href={props.url} target='_blank' rel='noopener noreferrer' className="text">{props.degree}</a><br/>
                <a href={props.url} target='_blank' rel='noopener noreferrer' className="text degree--responsive--university">{props.university}</a>
                <div className="degree--info">
                    <p className='text'>{props.period}</p>
                    <p className='text'>{props.city} <img src={props.flag} alt="Flag" /></p>
                </div>
            </div>
        </>
    )
}