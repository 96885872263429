import './Footer.css'

import logo_img from '../../assets/imgs/logo.png'

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer--copyright">
                <p className='text'>&copy; 2023 Danilo Ortiz Rodrigues.</p>
                <p className="text">All rights reserved.</p>
            </div>
            <div className="footer--logo">
                <img src={logo_img} alt="Logo" />
            </div>
            <div className="footer--sources">
                <p className="text">Icons and images by <a className='text' href='https://www.freepik.com/'>FreePik</a></p>
                <p className="text">Made with <a className='text' href='https://alvarotrigo.com/fullPage/'>FullPage.js</a></p>
            </div>
        </div>
    )
}