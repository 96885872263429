import { useTranslation } from 'react-i18next'
import './LangButton.css'

export default function LangButton(props) {
    const { i18n } = useTranslation()

    const handleClick = () => i18n.changeLanguage(props.lang)

    return (
        <button className="lang-button" onClick={handleClick}>
            <img src={props.img} alt={props.lang} />
        </button>
    )
}