import './SkillsSlider.css'

import { useLayoutEffect } from 'react'
import SkillItem from '../SkillItem'

import arrow_img from '../../assets/imgs/arrow_single.png'
import matlab_img from '../../assets/imgs/matlab.png'
import solidedge_img from '../../assets/imgs/solidedge.png'
import python_img from '../../assets/imgs/python.png'
import html_img from '../../assets/imgs/html.png'
import brain_img from '../../assets/imgs/brain.png'
import mooc_img from '../../assets/imgs/mooc.png'
import simulink_img from '../../assets/imgs/simulink.png'
import cpp_img from '../../assets/imgs/cpp.png'
import javascript_img from '../../assets/imgs/javascript.png'
import css_img from '../../assets/imgs/css.png'
import car_img from '../../assets/imgs/car.png'
import game_img from '../../assets/imgs/game.png'

import { useTranslation } from 'react-i18next'

export default function SkillsSlider() {
    const { t } = useTranslation('skills')

    const slideDown = () => {
        const items = document.querySelector('.skills--items')
        const slider = document.querySelector('.skills--slider')

        const newPos = Math.max(items.offsetTop-230, -(items.clientHeight - slider.clientHeight))
        items.style.top = `${newPos}px`
        
        const up = document.querySelector('.skills--slider--up')
        const down = document.querySelector('.skills--slider--down')

        up.classList.add('skills--slider--visible')
        if(newPos <= -(items.clientHeight - slider.clientHeight))
            down.classList.remove('skills--slider--visible')
    }

    const slideUp = () => {
        const items = document.querySelector('.skills--items')

        const newPos = Math.min(items.offsetTop+230, 0)
        items.style.top = `${newPos}px`
        
        const up = document.querySelector('.skills--slider--up')
        const down = document.querySelector('.skills--slider--down')

        down.classList.add('skills--slider--visible')
        if(newPos >= 0)
            up.classList.remove('skills--slider--visible')
    }

    useLayoutEffect(() => {
        const handleOverflow = () => {
            const slider = document.querySelector('.skills--slider')
            const items = document.querySelector('.skills--items')
    
            const up = document.querySelector('.skills--slider--up')
            const down = document.querySelector('.skills--slider--down')
            
            if (items.clientHeight > slider.clientHeight) {
                if(items.offsetTop < 0)
                    up.classList.add('skills--slider--visible')
                
                if(items.offsetTop > -(items.clientHeight - slider.clientHeight))
                    down.classList.add('skills--slider--visible')
                    
                slider.classList.add('skills--slider--border')
            } else {
                up.classList.remove('skills--slider--visible')
                down.classList.remove('skills--slider--visible')
                
                slider.classList.remove('skills--slider--border')
            }
        }

        handleOverflow()
        window.addEventListener('resize', handleOverflow)
        return () => window.removeEventListener('resize', handleOverflow)
    }, [])

    return (
        <div className="skills--slider">
            <div onClick={slideUp} className="skills--slider--up">
                <img src={arrow_img} alt="Up" />
            </div>
            <div onClick={slideDown} className="skills--slider--down">
                <img src={arrow_img} alt="Down" />
            </div>
            <div className="skills--items">
                <SkillItem
                    img={matlab_img}
                    skill={t('slider.matlab.name')}
                    text={t('slider.matlab.text')}
                    info_url='https://mathworks.com/products/matlab.html'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
                <SkillItem
                    img={solidedge_img}
                    skill={t('slider.solidedge.name')}
                    text={t('slider.solidedge.text')}
                    info_url='https://solidedge.siemens.com/'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
                <SkillItem
                    img={python_img}
                    skill={t('slider.python.name')}
                    text={t('slider.python.text')}
                    info_url='https://www.python.org/about/'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
                <SkillItem
                    img={html_img}
                    skill={t('slider.html.name')}
                    text={t('slider.html.text')}
                    info_url='https://wikipedia.org/wiki/HTML'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
                <SkillItem
                    img={brain_img}
                    skill={t('slider.dl.name')}
                    text={t('slider.dl.text')}
                    info_url='https://www.coursera.org/specializations/deep-learning'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url='
                    https://www.coursera.org/account/accomplishments/specialization/certificate/XENP32F9CQM5'
                />
                <SkillItem
                    img={mooc_img}
                    skill={t('slider.mooc.name')}
                    text={t('slider.mooc.text')}
                    info_url='https://gestiondeprojet.pm/cours/'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
                <SkillItem
                    img={simulink_img}
                    skill={t('slider.simulink.name')}
                    text={t('slider.simulink.text')}
                    info_url='https://mathworks.com/products/simulink.html'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
                <SkillItem
                    img={cpp_img}
                    skill={t('slider.cpp.name')}
                    text={t('slider.cpp.text')}
                    info_url='https://wikipedia.org/wiki/C%2B%2B'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
                <SkillItem
                    img={javascript_img}
                    skill={t('slider.js.name')}
                    text={t('slider.js.text')}
                    info_url='https://wikipedia.org/wiki/JavaScript'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
                <SkillItem
                    img={css_img}
                    skill={t('slider.css.name')}
                    text={t('slider.css.text')}
                    info_url='https://wikipedia.org/wiki/Cascading_Style_Sheets'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
                <SkillItem
                    img={car_img}
                    skill={t('slider.selfdrivingcars.name')}
                    text={t('slider.selfdrivingcars.text')}
                    info_url='https://www.coursera.org/specializations/self-driving-cars'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url='https://www.coursera.org/account/accomplishments/specialization/certificate/Q9EY7W58FMTD'
                />
                <SkillItem
                    img={game_img}
                    skill={t('slider.gamedev.name')}
                    text={t('slider.gamedev.text')}
                    info_url='https://wikipedia.org/wiki/Video_game_development'
                    info={t('slider.info')}
                    certificate={t('slider.certificate')}
                    certificate_url=''
                />
            </div>
        </div>
    )
    
}