import './Loading.css'

import signature_img from '../../assets/imgs/signature.gif'

export default function Loading(props) {
    return (
        <div className='loading' style={{display : `${props.show?'flex':'none'}`}}>
            <div className="loading--image">
                <img src={signature_img} alt="Loading" />
            </div>
        </div>
    )
}