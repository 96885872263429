import { useState } from 'react'
import './ContactButton.css'

export default function ContactButton(props) {
    const [hover, setHover] = useState(false)

    function redirect(url) {
        window.open(url, '_blank')
    }

    return (
        <div className="contact-button"
            style={{backgroundColor : props.color}}
            onClick={()=>redirect(props.url)}
            onMouseEnter={()=>setHover(true)}
            onMouseLeave={()=>setHover(false)}>
            
            <div className="contact-button--image">
                <img src={hover&&props.img_hover?
                            props.img_hover:props.img}
                    alt={props.text} />
            </div>
            <div className="contact-button--text">
                {props.sub?
                    <div className='contact-button--centralizer'></div>
                    :''}
                <p className="text">{props.text}</p>
                {props.sub?
                    <p className="text contact-button--sub">
                        {props.sub}
                    </p>:''}
            </div>
        </div>
    )
}