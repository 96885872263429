import './Education.css'

import studies_img from '../../assets/imgs/studies.png'
import fr_img from '../../assets/imgs/fr-2.png'
import br_img from '../../assets/imgs/br-2.png'

import Arrow from '../Arrow'
import Degree from '../Degree'

import { useTranslation } from 'react-i18next'

export default function Education(props) {
    const { t } = useTranslation('education')

    return (
        <div className="education">
            <div className="education--wrapper">
                <div className="education--title">
                    <p className="title">{t('title')}</p>
                </div>
                <div className="education--responsive--image">
                    <img src={studies_img} alt="Professor" />
                </div>
                <div className="education--content">
                    <div className="education--image">
                        <img src={studies_img} alt="Professor" />
                    </div>
                    <div className="education--text">
                        <Degree
                            degree={t('degrees.centrale.degree')}
                            university={t('degrees.centrale.university')}
                            period={t('degrees.centrale.period')}
                            city={t('degrees.centrale.city')}
                            flag={fr_img}
                            url='https://centralelille.fr/'
                            />
                        <Degree
                            degree={t('degrees.usp.degree')}
                            university={t('degrees.usp.university')}
                            period={t('degrees.usp.period')}
                            city={t('degrees.usp.city')}
                            flag={br_img}
                            url='http://www.saocarlos.usp.br/'
                        />
                    </div>
                </div>
            </div>
            <Arrow nextSectionCallback={props.nextSectionCallback} />
        </div>
    )
}