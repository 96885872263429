import './About.css'

import Arrow from '../Arrow'
import Computer from '../Computer'

import { useTranslation } from 'react-i18next'

export default function About(props) {
    const { t } = useTranslation('about')

    return (
        <div className="about">
            <div className="about--wrapper">
                <div className="about--title">
                    <p className="title">{t('title')}</p>
                </div>
                <div className="about--responsive--image">
                    <Computer />
                </div>
                <div className="about--content">
                    <div className="about--text">
                        <p className="text">
                            {t('text')}
                        </p>
                    </div>
                    <div className="about--image">
                        <Computer />
                    </div>
                </div>
            </div>
            <Arrow nextSectionCallback={props.nextSectionCallback} />
        </div>
    )
}