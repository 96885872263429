import './Home.css'

import profile_img from '../../assets/imgs/profile.png'
import br_img from '../../assets/imgs/br.png'
import us_img from '../../assets/imgs/us.png'
import fr_img from '../../assets/imgs/fr.png'

import LangButton from '../LangButton'
import Arrow from '../Arrow'

import { Trans, useTranslation } from 'react-i18next'

export default function Home(props) {
    const { t } = useTranslation('home')

    return (
        <div className="home">
            <div className="home--left">
                <div className="home--lang">
                    <LangButton img={br_img} lang='pt' />
                    <LangButton img={us_img} lang='en' />
                    <LangButton img={fr_img} lang='fr' />
                </div>
                <div className="home--profile-responsive"></div>
                <div className="home--text">
                    <p>{t('greeting')}</p>
                    <h1>Danilo Rodrigues</h1>
                    <Trans i18nKey="engineering" t={t}><p>I'm a</p> <h1>Mechatronics <span>&</span> Multidisciplinary</h1> <p>Engineering student</p></Trans>
                </div>
            </div>
            <img className="home--right" src={profile_img} alt="Profile" />
            <Arrow nextSectionCallback={props.nextSectionCallback} />
        </div>
    )
}