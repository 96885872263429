function closePopup(element, background, popup, title, text, links) {
    background.classList.remove('skill-item--popup--open')
    popup.classList.remove('skill-item--popup--open')
    title.classList.remove('skill-item--popup--open')
    text.classList.remove('skill-item--popup--open')
    links.classList.remove('skill-item--popup--open')

    setTimeout(()=>element.remove(), 500)
}

function createPopup(props) {
    const content = `
        <div class='skill-item--popup--background'></div>
        <div class='skill-item--popup'>
            <div class="skill-item--popup--close">
                <img src='${props.close_img}' alt="Close" />
            </div>
            <div class="skill-item--popup--content">
                <div class="skill-item--popup--image">
                    <img src='${props.img}' alt=${props.skill} />
                </div>
                <div class="skill-item--popup--body">
                    <div class="skill-item--popup--title">
                        <p class="text">${props.skill}</p>
                    </div>
                    <div class="skill-item--popup--text">
                        <p class="text">${props.text}</p>
                    </div>
                </div>
                <div class="skill-item--popup--links">
                    <div class="skill-item--popup--moreinfo">
                        <a target='_blank' rel='noopener noreferrer' href=${props.info_url} class="text">${props.info}</a>
                    </div>
                    ${props.certificate_url ? `<div><a target={'_blank'} rel='noopener noreferrer' href=${props.certificate_url} class='text'>${props.certificate}</a></div>`:''}
                </div>
            </div>
        </div>
    `

    const element = document.createElement('div')
    element.innerHTML = content
    element.classList.add('skill-item--popup--group')

    document.querySelector('.app').insertAdjacentElement('afterbegin', element)

    const background = document.querySelector('.skill-item--popup--background')
    const popup = document.querySelector('.skill-item--popup')
    const closeButton = document.querySelector('.skill-item--popup--close')
    const title = document.querySelector('.skill-item--popup--title')
    const text = document.querySelector('.skill-item--popup--text')
    const links = document.querySelector('.skill-item--popup--links')

    background.onclick = () => closePopup(element, background, popup, title, text, links)
    closeButton.onclick = () => closePopup(element, background, popup, title, text, links)

    setTimeout(() => {
        background.classList.add('skill-item--popup--open')
        popup.classList.add('skill-item--popup--open')
        title.classList.add('skill-item--popup--open')
        text.classList.add('skill-item--popup--open')
        links.classList.add('skill-item--popup--open')
    }, 10)
}

module.exports = {
    createPopup
}